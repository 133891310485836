import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'primereact/button';
import { baseUrlAdmin } from './../../../store/apiConstants';
import { MELUHA_BOARD_ID } from './../../../store/actionTypes';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { Dropdown } from 'primereact/dropdown';
import { connect } from 'react-redux';
import {
    getActiveOrganizationData,
} from '../../../store/actions';

import InputTextB from './../../customComponents/inputTextB';

class AddSubject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            classData: [],
            subjectData: [],
            chapterData: [],
            board: [],
            groups: [],
            classes: [],
            subjects: [],
            userBoard: props.data && props.data.boardId ? props.data.boardId : '',
            userClass: props.data && props.data.classId ? props.data.classId : '',
            userGroup: props.data && props.data.groupId ? props.data.groupId : '',
            boardName: props.data && props.data.boardName ? props.data.boardName : '',
            className: props.data && props.data.className ? props.data.className : '',
            groupName: props.data && props.data.groupName ? props.data.groupName : '',
            isAssigned: props.data ? true : false,
            errorMsg: '',
            subjectId: props.data && props.data.subjectId ? props.data.subjectId : '',
            subjectName: props.data && props.data.subjectName ? props.data.subjectName : '',

        }
    }

    checkIsAssigned = (subjectId) => {
        let isAssigned = false;
        this.props.assignedSubjects.forEach(g => {
            if (g.data && g.data.subjectId == subjectId) {
                isAssigned = true;
            }
        });
        return isAssigned
    }

    getCurriculum(boardId, classId, groupId, isEdit) {

        let config = {
            headers: {
                'Access-Control-Allow-Origin': '*',
            }
        }
        axios.get(`${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`, config)
            .then(({ data }) => {
                let subjects = data.Item.subjects.filter((a => {
                    if (!this.checkIsAssigned(a.subjectId) || isEdit) {
                        return {
                            subjectId: a.subjectId,
                            subjectName: a.subjectName
                        }
                    }
                }));
                this.setState({
                    subjects,
                    allSubjects: data.Item.subjects
                });
            })
            .catch(e => {
                console.log(e);
            });
    }


    getCurriculumData = (isEdit) => {

        const { userBoard, userClass, userGroup } = this.state;
        if (userBoard && userClass && userGroup) {
            this.getCurriculum(userBoard, userClass, userGroup, isEdit);
        }
    };


    getSubjectName = (id) => {
        const _subject =
            this.state.subjects && this.state.subjects.length && this.state.subjects.find((s) => s.subjectId === id);
        return (_subject && _subject.subjectName) || ' ';
    };

    getTopicName = (id, topicsInfo) => {
        let topicData = this.state.topicsMultiple;
        if (topicsInfo) topicData = topicsInfo;
        const _topic = topicData && topicData.length > 0 && topicData.find((t) => t.topicId === id);
        return (_topic && _topic.topicName) || ' ';
    };

    getChapterName = (id, chaptersInfo) => {
        let chapterData = this.state.chaptersMultiple;
        if (chaptersInfo) chapterData = chaptersInfo;
        const _chapter = chapterData && chapterData.length > 0 && chapterData.find((c) => c.chapterId === id);
        return (_chapter && _chapter.chapterName) || ' ';
    };

    getBoardName = (id) => {
        const _board = this.state.board && this.state.board.length && this.state.board.find((b) => b.boardId === id);
        return (_board && _board.boardName) || ' ';
    };

    getClassName = (id) => {
        const _class = this.state.classes && this.state.classes.length && this.state.classes.find((c) => c.classId === id);
        return (_class && _class.className) || '';
    };

    getGroupName = (id) => {
        const _group = this.state.groups && this.state.groups.length > 0 && this.state.groups.find((g) => g.groupId === id);
        return (_group && _group.groupName) || ' ';
    };

    handleChangeBoard = (boardId) => {
        const boardName = this.getBoardName(boardId);
        this.setState({ classes: [], groups: [], userClass: '', userGroup: '' });
        const _classes = [];
        const selectedBoard =
            this.props.boardsInfo &&
            this.props.boardsInfo.boards &&
            this.props.boardsInfo.boards.length > 0 &&
            this.props.boardsInfo.boards.find((board) => board.boardId === boardId);
        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.map((item) => _classes.push({ className: item.className, classId: item.classId }));
            this.setState({
                classes: _classes,
                userBoard: boardId,
                userClass: '',
                userGroup: '',
                subjectId: '',
                groups: [],
                subjects: [],
                boardName,
                isAssigned: false,


            },
                () => {
                    this.props.onAssignClick(
                        {
                            id: this.props.id,
                            data: {

                            },
                            assigned: false
                        });
                });
        }
    };

    handleChangeClass = (classId) => {

        const className = this.getClassName(classId);
        this.setState({ groups: [], userGroup: '' });
        const boardId = this.state.userBoard;
        const _groups = [];
        const selectedBoard =
            this.props.boardsInfo.boards &&
            this.props.boardsInfo.boards.length > 0 &&
            this.props.boardsInfo.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        this.setState({
            user: { ...this.state.user, grade: selectedClass && selectedClass.className }
        });
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ groupName: item.groupName, groupId: item.groupId }));

            const groupId = _groups[0].groupId
            const groupName = this.getGroupName(groupId);


            this.setState(
                {
                    groups: _groups,
                    userClass: classId,
                    className,
                    userGroup: '',
                    subjectId: '',
                    subjects: [],
                    chapters: [],
                    topics: [],
                    isAssigned: false,
                    userGroup: groupId,
                    subjects: [],
                    subjectName: '',
                    groupName,
                },
                () => {
                    this.getCurriculumData();
                    this.props.onAssignClick(
                        {
                            id: this.props.id,
                            data: {

                            },
                            assigned: false
                        });
                }
            );
        }
    };

    handleChangeGroup = (groupId) => {
        const groupName = this.getGroupName(groupId);
        this.setState(
            {
                userGroup: groupId,
                subjects: [],
                subjectName: '',
                subjectId: '',
                groupName,
                isAssigned: false
            },
            () => {
                this.getCurriculumData();
                this.props.onAssignClick(
                    {
                        id: this.props.id,
                        data: {

                        },
                        assigned: false
                    });
            }

        );
    };

    handleChangeSubject = (subjectId) => {
        const subjectName = this.getSubjectName(subjectId)
        this.setState(
            {
                subjectName,
                subjectId,
                isAssigned: false
            },
            () => {
                this.props.onAssignClick(
                    {
                        id: this.props.id,
                        data: {

                        },
                        assigned: false
                    })
            }
        );
    };


    assignCurriculum = () => {
        const { userBoard, userClass, userGroup, subjectId, subjectName, subject, boardName, className,
            groupName
        } = this.state;

        if (userBoard != '' && userClass != '' && userGroup != '' && subjectId != '') {
            this.setState({
                isAssigned: true
            }, () => {
                this.props.onAssignClick(
                    {
                        id: this.props.id,
                        data: {
                            boardId: userBoard,
                            classId: userClass,
                            groupId: userGroup,
                            subjectName,
                            subjectId,
                            boardName,
                            className,
                            groupName
                        },
                        assigned: true
                    });
            })
        } else {
            this.setState({
                errorMsg: 'select board, class, group, subject'
            });

            setTimeout(() => {
                this.setState({
                    errorMsg: ''
                });
            }, 3000);
        }



    }

    checkIsAssignedInOthers = (subjectId) => {
        let isAssigned = false;
        this.props.assignedSubjects.forEach(g => {
            if ((g.data && g.data.subjectId == subjectId) && g.id != this.props.id) {
                isAssigned = true;
            }
        });
        return isAssigned
    }


    onEditClick = () => {
        if (this.props.assignedSubjects.every((s) => s.assigned === true)) {

            let items = this.state.allSubjects.filter(a => !(this.checkIsAssignedInOthers(a.subjectId)))

            this.setState({
                isAssigned: false,
                subjects: items
            }, () => {
                this.props.onAssignClick(
                    {
                        id: this.props.id,
                        assigned: false
                    });
            });
        } else {
            this.toast.show({ severity: 'warn', summary: 'Warning', detail: 'Please add or remove which is in edit state.', life: 3000 });
        }

    }

    componentDidMount() {
        if (this.props.boardsInfo) {
            const boards = [];
            this.props.boardsInfo.boards &&
                this.props.boardsInfo.boards.map((item, index) => {
                    if (item.boardId !== MELUHA_BOARD_ID) {
                        boards.push({ boardId: item.boardId, boardName: item.boardName });
                    }
                });
            this.setState(
                {
                    board: boards
                });
        }
        if (!this.props.boardsInfo) {
            this.props.getActiveOrganizationData();
        } else {
            if (this.props.data) {
                this.formData();
            }
        }
    }

    formData = () => {
        const boardName = this.getBoardName(this.props.data.boardId);
        const _classes = [];
        const _groups = [];
        const selectedBoard =
            this.props.boardsInfo &&
            this.props.boardsInfo.boards &&
            this.props.boardsInfo.boards.length > 0 &&
            this.props.boardsInfo.boards.find((board) => board.boardId === this.props.data.boardId);
        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.map((item) => _classes.push({ className: item.className, classId: item.classId }));
        }

        const className = this.getClassName(this.props.data.classId);

        const selectedClass = selectedBoard && selectedBoard.classes.find((_class) => _class.classId === this.props.data.classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ groupName: item.groupName, groupId: item.groupId }));
            this.setState(
                {
                    classes: _classes,
                    groups: _groups,
                    boardName,
                    className,

                }, () => {
                    this.getCurriculumData(true);
                });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.boardsInfo !== this.props.boardsInfo) {
            if (this.props.boardsInfo) {
                const boards = [];
                this.props.boardsInfo.boards &&
                    this.props.boardsInfo.boards.map((item, index) => {
                        if (item.boardId !== MELUHA_BOARD_ID) {
                            boards.push({ boardId: item.boardId, boardName: item.boardName });
                        }
                    });
                this.setState(
                    {
                        board: boards
                    },
                    () => {
                        if (this.props.data) {

                            this.formData();
                        }
                    }
                );
            }
        }
    }


    render() {
        return (
            <div className='p-grid' >

                <div className='p-col-12 p-md-12'>
                    {/* <InputTextB info='Enter the name of the organization registered as per the ROC' id="cu10"> */}
                    <div className='p-grid ma-w100p' >
                        <div className='p-col-12 p-md-6 p-md-2-5 p-mb-1'>
                            <Dropdown value={this.state.userBoard}
                                disabled={this.state.isAssigned}
                                className='ma-w100p'
                                options={this.state.board.map(a => { return { label: a.boardName, value: a.boardId } })}
                                onChange={(e) => this.handleChangeBoard(e.value)}
                                placeholder="Select Board" />
                        </div>
                        <div className='p-col-12 p-md-6 p-md-2-5 p-mb-1'>
                            <Dropdown value={this.state.userClass}
                                disabled={this.state.isAssigned}
                                className='ma-w100p '
                                options={this.state.classes.map(a => { return { label: a.className, value: a.classId } })}
                                onChange={(e) => this.handleChangeClass(e.value)}
                                placeholder="Select Class" />
                        </div>
                        {/* <div className='p-col-12 p-md-6 p-md-2-5 p-mb-1'>
                            <Dropdown value={this.state.userGroup}
                                disabled={this.state.isAssigned}
                                className='ma-w100p '
                                options={this.state.groups.map(a => { return { label: a.groupName, value: a.groupId } })}
                                onChange={(e) => this.handleChangeGroup(e.value)}
                                placeholder="Select Group" />
                        </div> */}
                        <div className='p-col-12 p-md-6 p-md-2-5 p-mb-1'>
                            <Dropdown value={this.state.subjectId}
                                disabled={this.state.isAssigned}
                                className='ma-w100p'
                                options={this.state.subjects.map(a => { return { label: a.subjectName, value: a.subjectId } })}
                                onChange={(e) => this.handleChangeSubject(e.value)}
                                placeholder="Select Subject" />
                        </div>

                        <div className='p-col-12 p-md-6 p-md-2-5 p-mb-1'>
                            {this.state.isAssigned ? <Button
                                label={`Edit`}
                                onClick={this.onEditClick}
                            />

                                : <Button
                                    label={`Assign`}
                                    onClick={this.assignCurriculum}
                                />
                            }

                            <span className="ma-ml10 ma-pointer" onClick={() => { this.props.removeTempSubject(this.props.id) }}>
                                <i className="pi pi-times"></i>
                            </span>
                        </div>
                    </div>

                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                    {/* </InputTextB> */}
                    <p style={{ color: 'var(--required)', fontSize: '14px' }}>{this.state.errorMsg}</p>
                </div>
            </div>
        )
    }
}




const mapStateToProps = state => {
    return {
        boardsInfo:
            state.organizationData &&
            state.organizationData.response &&
            state.organizationData.response.Item &&
            state.organizationData.response.Item,
    }
}


export default connect(mapStateToProps, {
    getActiveOrganizationData,
})(withRouter(AddSubject));